@layer base {
  .form {
  padding: var(--sk-space-16) 0;
}

.form fieldset {
  border: 0;
}

.form fieldset, .actions {
  display: flex;
  gap: var(--sk-space-8);
  padding: var(--sk-space-8) 0;
}

}