@layer base {
  .main {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.container {
  display: flex;
  position: relative;
  justify-content: flex-start;
  margin: var(--sk-space-24);
  gap: var(--sk-space-16);
}

.title {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  color: var(--sk-color-grey-700);
}

.content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-24);
}

.content > div:first-child {
  height: 100%;
}

.tag {
  margin-top: var(--sk-space-42);
}

.edit {
  position: absolute;
  top: calc(var(--sk-space-8) * -1);
  right: 0;
}

.footer {
  padding: var(--sk-space-24);
  border-top: 1px solid var(--sk-color-grey-100);
}

@media (--sk-medium-viewport) {
  .tag {
    min-width: 7rem;
  }
}

@media (--sk-small-viewport-only) {
  .container {
    padding-right: var(--sk-space-64);
  }

  .content {
    flex-direction: column;
    align-items: flex-start;
  }
}

}