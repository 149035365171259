@layer base {
  .main {
  gap: var(--sk-space-40);
  display: flex;
  flex-direction: column;
  padding: 0 var(--sk-space-16);
}

.captionBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.subtitle {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-16);
}

.buttonsBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--sk-space-16);
}

.note {
  color: var(--sk-color-grey-400);
}

@media (--large-viewport) {
  .subtitle {
    width: 80%;
  }
}

}