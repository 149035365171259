@layer base {
  .list {
  display: flex;
  gap: var(--sk-space-16);
  padding: var(--sk-space-16) 0;
}

.subTitle {
  margin: var(--sk-space-16) 0;
}

}