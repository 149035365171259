@layer base {
  .main {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: calc(100% + var(--sk-space-32));
  border-radius: 0;
  background-color: black;
  background-repeat: no-repeat;
}

.main.completed {
  height: 420px;
  background-image: var(--captionBackground);
}

.main.uncompleted {
  height: 330px;
  background-image: var(--captionBackground);
}

.captionTitle {
  margin: var(--sk-space-16) var(--sk-space-72);
  color: var(--sk-color-white);
}

@media (--medium-viewport) {
  .main {
    border-radius: var(--sk-radius-16);
  }

  .main.completed {
    width: 578px;
  }

  .main.uncompleted {
    width: 600px;
  }
}

}