@layer modules, ui, base;
@layer base {
  .page_main__nxpkZ {
  gap: var(--sk-space-40);
  display: flex;
  flex-direction: column;
  padding: 0 var(--sk-space-16);
}

.page_captionBlock__KuikD {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.page_subtitle__wkNNO {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-16);
}

.page_buttonsBlock__PA9Rt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--sk-space-16);
}

.page_note__HIp2A {
  color: var(--sk-color-grey-400);
}

@media (min-width: 64rem) {
  .page_subtitle__wkNNO {
    width: 80%;
  }
}

}
@layer base {
  .TitleCaption_main__vUFyj {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: calc(100% + var(--sk-space-32));
  border-radius: 0;
  background-color: black;
  background-repeat: no-repeat;
}

.TitleCaption_main__vUFyj.TitleCaption_completed__znfXj {
  height: 420px;
  background-image: var(--captionBackground);
}

.TitleCaption_main__vUFyj.TitleCaption_uncompleted__dcH4k {
  height: 330px;
  background-image: var(--captionBackground);
}

.TitleCaption_captionTitle__QMqHC {
  margin: var(--sk-space-16) var(--sk-space-72);
  color: var(--sk-color-white);
}

@media (min-width: 48rem) {
  .TitleCaption_main__vUFyj {
    border-radius: var(--sk-radius-16);
  }

  .TitleCaption_main__vUFyj.TitleCaption_completed__znfXj {
    width: 578px;
  }

  .TitleCaption_main__vUFyj.TitleCaption_uncompleted__dcH4k {
    width: 600px;
  }
}

}
@layer ui {
  .sk-Typography_main__I32O9 {
  margin: unset;
  padding: unset;
}

/**
 * Tokens seems wrong for font families and weights but they are not.
 * The naming constraint came from Token Studio in Figma part where tokens are
 * generated. Token Studio creates as many tokens as there are variants.
 * For example, if we have the following configuration:
 * 3 hierarchy variants
 * 3 weights variants
 * 2 font families
 * Token Studio will create: 3 * 3 * 2 = 18 tokens.
 * Instead, we can handle variants separately and have only 3 + 3 + 2 = 8 tokens.
 * In our case, all Text class names use:
 * - the same font family
 * - the same font weight according to weight variant
 *   (regardless of the font used: body1, body2 etc.).
 */

/**
 * Font families
 */

.sk-Typography_title1__kpKKc,
.sk-Typography_title2___x7oz,
.sk-Typography_display1__01oEX,
.sk-Typography_display2__CnKww {
  font-family: var(--sk-typography-title-1-font-family), helvetica, arial, sans-serif;
}

.sk-Typography_body1__rvFYv,
.sk-Typography_body2__W0CNH,
.sk-Typography_title3__c40PS,
.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-family: var(--sk-typography-body-1-regular-font-family), helvetica, arial, sans-serif;
}

/**
 * Weights
 */

.sk-Typography_regular__a_y2X {
  font-weight: var(--sk-typography-body-1-regular-font-weight);
}

.sk-Typography_semiBold__OC7cU {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
}

.sk-Typography_bold__ICdus {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

/**
 * @FIXME: title token has been moved (.title1 uses --…-title-2) for mobile handling.
 *   See with @valentinmichel-jt for what needs to be done to make it more maintainable.
 *   N.B. .title4 is not handled yet and, in this case, it will miss the --…-title-5 token.
 *        We'll also have an n+1 missing token for each title variant added.
 */

.sk-Typography_title1__kpKKc {
  font-size: var(--sk-typography-title-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-2-line-height);
  text-decoration: none;
}

.sk-Typography_title2___x7oz {
  font-size: var(--sk-typography-title-3-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-3-line-height);
  text-decoration: none;
}

.sk-Typography_title3__c40PS {
  font-size: var(--sk-typography-title-4-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-4-line-height);
  text-decoration: none;
}

.sk-Typography_body1__rvFYv {
  font-size: var(--sk-typography-body-1-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-decoration: none;
}

.sk-Typography_body2__W0CNH {
  font-size: var(--sk-typography-body-2-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-decoration: none;
}

.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-size: var(--sk-typography-caption-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-caption-1-line-height);
  text-decoration: none;
}

.sk-Typography_caption1Underline__eZKaw {
  text-decoration: underline;
}

.sk-Typography_display1__01oEX {
  font-size: var(--sk-typography-display-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-1-line-height);
  text-decoration: none;
}

.sk-Typography_display2__CnKww {
  font-size: var(--sk-typography-display-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-2-line-height);
  text-decoration: none;
}

@media (min-width: 768px) {
  .sk-Typography_title1__kpKKc {
    font-size: var(--sk-typography-title-1-font-size);
    line-height: var(--sk-typography-title-1-line-height);
  }

  .sk-Typography_title2___x7oz {
    font-family: var(--sk-typography-title-2-font-family), helvetica, arial, sans-serif;
    font-size: var(--sk-typography-title-2-font-size);
    font-weight: var(--sk-typography-title-2-font-weight);
    line-height: var(--sk-typography-title-2-line-height);
  }

  .sk-Typography_title3__c40PS {
    font-size: var(--sk-typography-title-3-font-size);
    line-height: var(--sk-typography-title-3-line-height);
  }
}

}
@layer base {
  .Dropdown_main__Ov6FG {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
}

.Dropdown_trigger__jI4rN {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  border: 0;
  border-radius: var(--sk-radius-12);
  background: none;
  cursor: pointer;
  gap: var(--sk-space-8);
}

.Dropdown_trigger__jI4rN:hover {
  background-color: var(--sk-color-grey-100);
}

.Dropdown_menu__H_6SF {
  display: flex;
  position: absolute;
  z-index: var(--z-index-modal);
  top: var(--sk-space-40);
  flex-direction: column;
  width: calc(100vw - var(--sk-space-32) * 2);
  margin: 0;
  padding: var(--sk-space-16);
  list-style-type: none;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
  box-shadow: 0 13px 60px 0 var(--sk-color-grey-100);
}

.Dropdown_link__6shLn {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-4);
}

.Dropdown_itemLink__Y7crm {
  justify-content: flex-start;
  max-width: 100%;
  font-weight: var(--sk-typography-body-2-regular-font-weight);
}

@media (min-width: 48rem) {
  .Dropdown_menu__H_6SF {
    top: var(--sk-space-40);
    width: -moz-max-content;
    width: max-content;
  }
}

}
@layer base {
  .Logo_logo__mkjJe {
  color: var(--sk-color-black);
  font-family: var(--sk-font-families-polysans);
  letter-spacing: 1.5px;
  text-decoration: none;
}

}
@layer base {
  .Filters_form__Dc_de {
  padding: var(--sk-space-16) 0;
}

.Filters_form__Dc_de fieldset {
  border: 0;
}

.Filters_form__Dc_de fieldset, .Filters_actions__FUZNN {
  display: flex;
  gap: var(--sk-space-8);
  padding: var(--sk-space-8) 0;
}

}
@layer base {
  .List_main___UsqF {
  padding: var(--sk-space-16) 0;
}

}
@layer base {
  .Navigation_list__VKKBJ {
  display: flex;
  gap: var(--sk-space-16);
  padding: var(--sk-space-16) 0;
}

.Navigation_subTitle__74ozu {
  margin: var(--sk-space-16) 0;
}

}
@layer base {
  .Notifications_wrapper__BeeGX {
  padding: 3rem;
}

.Notifications_buttonList__i5An6 {
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

}
@layer base {
  .ScreeningQuestionsForm_main__ZRR8_ {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

}
@layer base {
  .ScreeningQuestions_heading__PqVte {
  margin-bottom: var(--sk-space-24);
}

.ScreeningQuestions_main__GczQU {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

}
@layer base {
  .ScreeningQuestionsDebug_debug__CL_qP pre {
  margin: 0;
  text-wrap: auto;
}

}
@layer base {
  .EmptyState_textDescription__4bjZq {
    height: calc(100vh - 225px);
    margin-top: 8px;
    margin-bottom: 24px;
}

.EmptyState_main__qcnNp {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EmptyState_button__LN_8f {
    text-align: center;
}


@media (min-width: 768px) {
    .EmptyState_textDescription__4bjZq {
        height: auto;
    }

    .EmptyState_button__LN_8f {
        width: -moz-fit-content;
        width: fit-content;
    }
}
}
@layer base {
  .CandidacyDocuments_main__Nip6G {
  margin-top: var(--sk-space-24);
}

.CandidacyDocuments_header__Twjuk {
  margin-bottom: var(--sk-space-24);
}

.CandidacyDocuments_buttonWrapper__8Sr4R {
  display: flex;
  gap: var(--sk-space-16);
}

.CandidacyDocuments_cvOnlyCard__mpKpe {
  display: flex;
  align-items: center;
  padding:  var(--sk-space-12) var(--sk-space-16);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  gap: var(--sk-space-16);
}

.CandidacyDocuments_cvOnlyCard__icon__dP1g1 {
  flex-shrink: 0;
}
}
@layer base {
  .CoverLetterShortText_coverLetterHeader__bqJdi {
  margin-top: var(--sk-space-24);
  margin-bottom: var(--sk-space-8);
}

.CoverLetterShortText_coverLetterShortText__xqFCD {
  white-space: pre-wrap;
}
}
@layer base {
  .PageDetail_divider__aeVfD {
  display: none;
}
  
.PageDetail_wrapper__td2E9 {
  display: flex;
  grid-column: 1 / 13;
  flex-direction: column;
}

.PageDetail_backLink__Z1_FV {
  display: flex;
  align-items: center;
  color: var(--sk-color-black);
  text-decoration: none;
  gap: var(--sk-space-4);
}

.PageDetail_header__W8nes {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-24);
}

@media (min-width: 1312px) {
  .PageDetail_divider__aeVfD {
    display: block;
    grid-column: 4 / 5;
    margin: 0 var(--sk-space-56) 0 var(--sk-space-40);
  }

  .PageDetail_wrapper__td2E9 {
    grid-column: 5 / 13;
  }
}
}
@layer base {
  .CardContent_main__qm4MT {
  display: flex;
  align-items: center;
}

/* Avatar */
.CardContent_avatar__JqCz3 {
  height: var(--sk-space-48);
  padding-right: var(--sk-space-8);
}

.CardContent_imgProfilePicture__Lkw_y {
  -o-object-fit: cover;
     object-fit: cover;
}

/* Texts */

.CardContent_textContent__Wwl_u {
  margin-right: auto;
}

.CardContent_secondaryText__D1rml {
  color: var(--sk-color-grey-500);
}

}
@layer base {
  .CardWrapper_main__jCbr4,
.CardWrapper_main__jCbr4:link,
.CardWrapper_main__jCbr4:visited {
    display: block;
    padding: var(--sk-space-12) var(--sk-space-16);
    border: var(--sk-space-2) solid var(--sk-color-black);
    border-radius: var(--sk-radius-8);
    color: inherit;
    text-decoration: none;
}

.CardWrapper_main__jCbr4:is(a):hover {
    background: var(--sk-color-dark-10);
}

.CardWrapper_main__jCbr4:is(a):focus-visible {
    outline: var(--sk-space-2) solid var(--sk-action-button-color-border-secondary-default-light);
    outline-offset: calc(-1 * var(--sk-space-2));
    background-color: var(--sk-action-button-color-background-secondary-focus-light);
    box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
    color: var(--sk-action-button-color-secondary-focus-light);
}

.CardWrapper_main__jCbr4:is(a):active {
    background: var(--sk-color-dark-20);
}

}
@layer base {
  .ClickableCard_trash__Obki3 {
    margin-left: var(--sk-space-32);
    color: var(--sk-color-error-500);
}
}
@layer base {
  .ExperienceViewCard_main__3xo0G {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.ExperienceViewCard_container__SQUx9 {
  display: flex;
  position: relative;
  justify-content: flex-start;
  margin: var(--sk-space-24);
  gap: var(--sk-space-16);
}

.ExperienceViewCard_title__Wae0o {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ExperienceViewCard_subtitle__tQwD9 {
  color: var(--sk-color-grey-700);
}

.ExperienceViewCard_content__1dFRy {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-24);
}

.ExperienceViewCard_content__1dFRy > div:first-child {
  height: 100%;
}

.ExperienceViewCard_description__hEsD_ {
  white-space: pre-wrap;
}

.ExperienceViewCard_tag__SCCbl {
  margin-top: var(--sk-space-42);
}

.ExperienceViewCard_edit__GzMud {
  position: absolute;
  top: calc(var(--sk-space-8) * -1);
  right: 0;
}

.ExperienceViewCard_footer__XqYr4 {
  padding: var(--sk-space-24);
  border-top: 1px solid var(--sk-color-grey-100);
}

@media (min-width: 768px) {
  .ExperienceViewCard_tag__SCCbl {
    min-width: 7rem;
  }
}

@media (max-width: 767px) {
  .ExperienceViewCard_container__SQUx9 {
    padding-right: var(--sk-space-64);
  }

  .ExperienceViewCard_content__1dFRy {
    flex-direction: column;
    align-items: flex-start;
  }
}

}
@layer base {
  .EmptyState_main__bYMyU {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--sk-space-16);
  max-width: 364px;
  margin-right: auto;
  margin-left: auto;
  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-space-40);
}

.EmptyState_description__cHSZY {
  color: var(--sk-color-grey-400);
  text-align: center;
}

}
@layer base {
  .CardBackdrop_backdrop__TwCra {
  position: fixed;
  z-index: var(--z-index-modal);
  inset: 0;
  height: 100dvh;
  animation: 0.2s ease-out 0s 1 CardBackdrop_show-backdrop__Lrsd5;
  opacity: 0.2;
  background-color: var(--sk-color-black);
}

@keyframes CardBackdrop_show-backdrop__Lrsd5 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

}
@layer base {
  .CardEdit_main__ob5O_ {
  display: flex;
  position: relative;
  /* to be above the backdrop */
  z-index: calc(var(--z-index-modal) + 1);
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-24);
  border: 2px solid var(--sk-color-black);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-white);
  gap: var(--sk-space-24);
}

.CardEdit_header__MJjqt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CardEdit_content__r_WpM {
  display: flex;
  gap: var(--sk-space-24);
}

.CardEdit_deleteBtn__qbY1z {
  --sk-action-button-color-background-primary-default-light: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-default-dark: var(--sk-color-error-500);
  --sk-action-button-color-background-primary-hover-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-hover-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-light: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-active-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-dark: var(--sk-color-error-550);
  --sk-action-button-color-background-primary-focus-light: var(--sk-color-error-550);
}

.CardEdit_actions__5Od9_ {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--sk-space-24);
  gap: var(--sk-space-24);
}

.CardEdit_actions__5Od9_ > div {
  display: flex;
  gap: var(--sk-space-16);
}

.CardEdit_actions__5Od9_ .CardEdit_confirmContainer__Gh0sr {
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .CardEdit_avatar__75ydA {
    display: none;
  }

  .CardEdit_actions__5Od9_ {
    display: flex;
    flex-direction: column-reverse;
    gap: var(--sk-space-16);
  }

  .CardEdit_actions__5Od9_ > div {
    width: 100%;
  }

  .CardEdit_deleteBtn__qbY1z,
  .CardEdit_confirmBtn__opK6g {
    width: 100%;
  }
}

}
@layer base {
  .Card_main__7ZVBs {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.Card_container__OoeAw {
  display: flex;
  position: relative;
  justify-content: flex-start;
  margin: var(--sk-space-24);
  gap: var(--sk-space-16);
}

.Card_title__6ipF8 {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card_subtitle__9Si4O {
  color: var(--sk-color-grey-700);
}

.Card_content__pqPuX {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: var(--sk-space-24);
}

.Card_content__pqPuX > div:first-child {
  height: 100%;
}

.Card_tag__M4Pex {
  margin-top: var(--sk-space-42);
}

.Card_edit__eW_xI {
  position: absolute;
  top: calc(var(--sk-space-8) * -1);
  right: 0;
}

.Card_footer__j96TA {
  padding: var(--sk-space-24);
  border-top: 1px solid var(--sk-color-grey-100);
}

@media (min-width: 768px) {
  .Card_tag__M4Pex {
    min-width: 7rem;
  }
}

@media (max-width: 767px) {
  .Card_container__OoeAw {
    padding-right: var(--sk-space-64);
  }

  .Card_content__pqPuX {
    flex-direction: column;
    align-items: flex-start;
  }
}

}
@layer base {
  .LanguageCard_form__7akVg {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--sk-space-16);
}

.LanguageCard_select__Qjy6Z {
  width: 100%;
}

@media (min-width: 768px) {
  .LanguageCard_form__7akVg {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .LanguageCard_select__Qjy6Z {
    display: flex;
    flex: 1;
    flex-basis: 100%;
    flex-direction: column;
  }
}

}
@layer base {
  .Languages_main__4rkwJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: var(--sk-space-16);
  gap: var(--sk-space-16);
}

.Languages_addLanguageButton__xw7D8 {
  width: -moz-fit-content;
  width: fit-content;
}

}
@layer base {
  .OtherExperienceViewCard_avatar__ZFRJF {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.OtherExperienceViewCard_avatar__ZFRJF img {
  -o-object-fit: contain;
     object-fit: contain;
}
}
@layer base {
  .ListPage_list__bv_9R {
  padding: 0;
}

.ListPage_list__item__5jDT7 {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ListPage_list__item__5jDT7:not(:last-child) {
  margin-bottom: var(--sk-space-24);
}

.ListPage_addButton__sGFSp {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

}
@layer base {
  .WorkExperienceViewCard_avatar__xFJfA {
  min-width: var(--sk-Avatar-size);
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-8);
}

.WorkExperienceViewCard_avatar__xFJfA img {
  -o-object-fit: contain;
     object-fit: contain;
}

.WorkExperienceViewCard_footer__6Gn1C {
  display: none;
}

@media (min-width: 768px) {
  .WorkExperienceViewCard_footer__6Gn1C {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--sk-space-48);
  }

  .WorkExperienceViewCard_footer__action__uWBbY {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}

}
@layer base {
  .ListPage_list__yrgQN {
  padding: 0;
}

.ListPage_list__item__ao2jP {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ListPage_list__item__ao2jP:not(:last-child) {
  margin-bottom: var(--sk-space-24);
}

.ListPage_addButton__g_y4t {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}

}
@layer base {
  .CoverLetter_main____MPD {
  margin-top: var(--sk-space-24);
}

@media (min-width: 1312px) {
  .CoverLetter_main____MPD {
    margin-top: var(--sk-space-32);
  }
}

.CoverLetter_addNewFile__BTa_O {
  width: 100%;
  margin-top: var(--sk-space-8);
}

.CoverLetter_error__ja_HS {
  margin-top: var(--sk-space-8);
  color: var(--sk-color-error-500);
  text-align: center;
}

.CoverLetter_header__2gKu8 {
  margin-bottom: var(--sk-space-16);
}

.CoverLetter_cvOnlyDescription__QWQsk {
  display: flex;
  align-items: center;
  padding: var(--sk-space-16) var(--sk-space-12);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  gap: var(--sk-space-16);
}

.CoverLetter_cvOnlyDescription__icon__qJetC {
  flex-shrink: 0;
}

}
