@layer base {
  .divider {
  display: none;
}
  
.wrapper {
  display: flex;
  grid-column: 1 / 13;
  flex-direction: column;
}

.backLink {
  display: flex;
  align-items: center;
  color: var(--sk-color-black);
  text-decoration: none;
  gap: var(--sk-space-4);
}

.header {
  margin-top: var(--sk-space-32);
  margin-bottom: var(--sk-space-24);
}

@media (--sk-large-viewport) {
  .divider {
    display: block;
    grid-column: 4 / 5;
    margin: 0 var(--sk-space-56) 0 var(--sk-space-40);
  }

  .wrapper {
    grid-column: 5 / 13;
  }
}
}