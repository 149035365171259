@layer base {
  .wrapper {
  padding: 3rem;
}

.buttonList {
  display: inline-flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

}