@layer base {
  .main {
  margin-top: var(--sk-space-24);
}

.header {
  margin-bottom: var(--sk-space-24);
}

.buttonWrapper {
  display: flex;
  gap: var(--sk-space-16);
}

.cvOnlyCard {
  display: flex;
  align-items: center;
  padding:  var(--sk-space-12) var(--sk-space-16);
  border-radius: var(--sk-radius-8);
  background-color: var(--sk-color-purple-100);
  gap: var(--sk-space-16);
}

.cvOnlyCard__icon {
  flex-shrink: 0;
}
}