@layer base {
  .textDescription {
    height: calc(100vh - 225px);
    margin-top: 8px;
    margin-bottom: 24px;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button {
    text-align: center;
}


@media (--sk-medium-viewport) {
    .textDescription {
        height: auto;
    }

    .button {
        width: fit-content;
    }
}
}