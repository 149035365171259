@layer base {
  .heading {
  margin-bottom: var(--sk-space-24);
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

}