@layer base {
  .main {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
}

.trigger {
  display: flex;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  border: 0;
  border-radius: var(--sk-radius-12);
  background: none;
  cursor: pointer;
  gap: var(--sk-space-8);
}

.trigger:hover {
  background-color: var(--sk-color-grey-100);
}

.menu {
  display: flex;
  position: absolute;
  z-index: var(--z-index-modal);
  top: var(--sk-space-40);
  flex-direction: column;
  width: calc(100vw - var(--sk-space-32) * 2);
  margin: 0;
  padding: var(--sk-space-16);
  list-style-type: none;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
  box-shadow: 0 13px 60px 0 var(--sk-color-grey-100);
}

.link {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-4);
}

.itemLink {
  justify-content: flex-start;
  max-width: 100%;
  font-weight: var(--sk-typography-body-2-regular-font-weight);
}

@media (--medium-viewport) {
  .menu {
    top: var(--sk-space-40);
    width: max-content;
  }
}

}